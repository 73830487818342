import React, {
  DetailedHTMLProps,
  PropsWithChildren,
  SelectHTMLAttributes
} from 'react';

type DropdownProps = DetailedHTMLProps<
  SelectHTMLAttributes<HTMLSelectElement>,
  HTMLSelectElement
>;
const Dropdown = ({ children, ...props }: PropsWithChildren<DropdownProps>) => (
  <div className="dropdown-select">
    <select className="dropdown-select-inner" {...props}>
      {children}
    </select>
  </div>
);

export default Dropdown;
